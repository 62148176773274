/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useOrganizationService } from "../../services/organization-service"
import EventFormDetails from "./forms/event-form-details"
import EventPreviewDetails from "./previews/event-preview-details"
import EventFormVenue from "./forms/event-form-venue"
import EventPreviewVenue from "./previews/event-preview-venue"
import EventFormAditional from "./forms/event-form-additional"
import EventPreviewAdditional from "./previews/event-preview-additional"
import CTA from "../cta"
import { useEventService } from "../../services/event-service"
import Container from "../container"
import OrganizationAccent from "./accent-organization"
import { Spinner } from "@theme-ui/components"
import EventFormTitle from "./event-form-title"

const EventFormView = ({ name, secret, ...restProps }) => {
  const {
    findBySlug,
    response: { data: organization },
  } = useOrganizationService()
  React.useEffect(() => {
    if (name && secret) findBySlug(name + "/" + secret)
  }, [name, secret, findBySlug])
  const [state, setState] = React.useState({
    details: "edit",
    venue: "hidden",
    additional: "hidden",
  })
  const { post, response: eventResponse } = useEventService()
  const [data, setData] = React.useState({})
  const renderTitle = (state, title) =>
    state !== "preview" ? (
      <h5 sx={{ mb: state === "hidden" ? 0 : "20px" }}>{title}</h5>
    ) : null
  const canSend =
    state.details === "preview" &&
    state.venue === "preview" &&
    state.additional === "preview"
  const submit = () => {
    const { eventImage, collectionImage, ...fields } = {
      ...data.details,
      ...data.venue,
      ...data.additional,
      organization: name + "/" + secret,
    }
    post({ data: fields, files: { eventImage, collectionImage } })
  }
  const eventRef = React.useRef(null)
  const venueRef = React.useRef(null)
  const additionalRef = React.useRef(null)
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      {organization && (
        <React.Fragment>
          <OrganizationAccent
            organization={organization}
            submitted={eventResponse.data}
          />
          {canSend ? (
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                mx: "auto",
                pb: "0px",
                width: ["90%", "95%", "700px"],
                textAlign: ["center", "center", "unset"],
                borderBottom: "1px solid #A5A5A5",
              }}
            >
              <h3 sx={{ mb: "69px" }}>Review And Submit Your Event</h3>
            </Container>
          ) : (
            <EventFormTitle />
          )}
          <Container
            sx={{
              display: "flex",
              py: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!eventResponse.data ? (
              <div sx={{ width: "100%", maxWidth: "760px", mt: 0 }}>
                <div
                  sx={{
                    ...(eventResponse.loading && {
                      opacity: 0.4,
                      pointerEvents: "none",
                    }),
                  }}
                >
                  <div
                    sx={{
                      px: ["20px", "20px", "50px"],
                      py: state.details === "hidden" ? "40px" : "50px",
                      borderBottom: "1px solid #c0c0c0",
                    }}
                    ref={eventRef}
                  >
                    {renderTitle(state.details, "Event Details")}
                    <EventFormDetails
                      key="details"
                      sx={{
                        display: state.details === "edit" ? "block" : "none",
                      }}
                      data={data.details}
                      onSubmit={newData => {
                        setData(data => ({ ...data, details: newData }))
                        setState(state => ({
                          ...state,
                          details: "preview",
                          ...(state.venue === "hidden" && { venue: "edit" }),
                        }))
                        scrollToRef(eventRef)
                      }}
                    />
                    {data.details && (
                      <EventPreviewDetails
                        sx={{
                          display:
                            state.details === "preview" ? "block" : "none",
                        }}
                        data={data.details}
                        onMakeChanges={() =>
                          setState(state => ({ ...state, details: "edit" }))
                        }
                      />
                    )}
                  </div>
                  <div
                    sx={{
                      px: ["20px", "20px", "80px"],
                      py: state.venue === "hidden" ? "40px" : "80px",
                      borderBottom: "1px solid #c0c0c0",
                    }}
                    ref={venueRef}
                  >
                    {renderTitle(state.venue, "Venue, Date, & Time")}
                    <EventFormVenue
                      key="venue"
                      sx={{
                        display: state.venue === "edit" ? "block" : "none",
                      }}
                      onSubmit={newData => {
                        setData(data => ({ ...data, venue: newData }))
                        setState(state => ({
                          ...state,
                          venue: "preview",
                          ...(state.additional === "hidden" && {
                            additional: "edit",
                          }),
                        }))
                        scrollToRef(venueRef)
                      }}
                    />
                    {data.venue && (
                      <EventPreviewVenue
                        sx={{
                          display: state.venue === "preview" ? "block" : "none",
                        }}
                        data={data.venue}
                        onMakeChanges={() =>
                          setState(state => ({ ...state, venue: "edit" }))
                        }
                      />
                    )}
                  </div>
                  <div
                    sx={{
                      px: ["20px", "20px", "80px"],
                      py: state.additional === "hidden" ? "40px" : "80px",
                    }}
                    ref={additionalRef}
                  >
                    {renderTitle(state.additional, "Additional Information")}
                    <EventFormAditional
                      organization={organization}
                      key="additional"
                      sx={{
                        display: state.additional === "edit" ? "block" : "none",
                      }}
                      onSubmit={newData => {
                        setData(data => ({ ...data, additional: newData }))
                        setState(state => ({
                          ...state,
                          additional: "preview",
                        }))
                        scrollToRef(additionalRef)
                      }}
                    />
                    {data.additional && (
                      <EventPreviewAdditional
                        organization={organization}
                        sx={{
                          display:
                            state.additional === "preview" ? "block" : "none",
                        }}
                        data={data.additional}
                        onMakeChanges={() =>
                          setState(state => ({ ...state, additional: "edit" }))
                        }
                      />
                    )}
                  </div>
                </div>
                {canSend && (
                  <div sx={{ px: ["20px", "20px", "80px"] }}>
                    <CTA disabled={eventResponse.loading} onClick={submit}>
                      {eventResponse.loading ? (
                        <Spinner sx={{ height: "20px" }} />
                      ) : (
                        "Submit Event"
                      )}
                    </CTA>

                    {eventResponse.loading && (
                      <div
                        sx={{
                          mt: "20px",
                          fontSize: "13px",
                          fontWeight: 600,
                          mb: "20px",
                        }}
                      >
                        Please wait as we upload your information. This may take
                        up to one minute.
                      </div>
                    )}

                    {eventResponse.error && !eventResponse.loading && (
                      <div
                        sx={{ fontSize: "12px", color: "primary", my: "5px" }}
                      >
                        Internal Server Error, please try again.
                      </div>
                    )}
                    <div sx={{ mt: "20px", fontSize: "13px", mb: "80px" }}>
                      When your submission is received and reviewed, you will
                      receive a notification, within 24 hours, that your event
                      has been published.
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                sx={{ p: "30px", maxWidth: "600px", mb: "100px", mt: "50px" }}
              >
                <h3 sx={{ fontSize: "35px", fontWeight: 600 }}>
                  Thank you, your event has been submitted.
                </h3>
                <div>
                  When your submission is received and reviewed, you will
                  receive a notification, within 24 hours, that your event has
                  been published
                </div>
                <CTA
                  onClick={() => window && window.location.reload()}
                  sx={{ mt: "30px" }}
                >
                  Submit Another Event
                </CTA>
              </div>
            )}
          </Container>
        </React.Fragment>
      )}
    </div>
  )
}

export default EventFormView
